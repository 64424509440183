import React from 'react'
import cx from 'classnames'

import { Hero } from '../../components/blog/hero'
import { PostSidebar } from '../../components/blog/post-sidebar'
import { Breadcrumbs, BreadcrumbsItem } from '../../components/breadcrumbs'
import { Container } from '../../components/container'
import SEO from '../../components/seo'
import img1 from './images/3-tipy-jak-jist-abyste-hubli.jpg'
import styles from './post-grid.module.css'

const TipyJakJistAbysteHubli = () => {
  return (
    <div className={styles.wrapper}>
      <SEO
        title={'3 tipy, jak jíst, abyste efektivně hubli | NutritionPro'}
        description={
          'Snaha o hubnutí může být velmi frustrující, pokud se vám nedaří dosáhnout výsledků, i když cvičíte a omezujete svou stravu. To ale může být přesně ten problém. Spoustu lidí se domnívá, že pro hubnutí je potřeba přestat jíst, vyhýbat se “špatným” potravinám a neustále co nejvíce cvičit. Stravování a fyzická aktivita jsou skutečně klíčové pro hubnutí. Jídelníček si však musíte nastavit správně a tak, aby pro vás byl udržitelný. My se dnes podíváme na 3 tipy, jak toho docílit a následně efektivně a zdravě zhubnout.'
        }
      />
      <Container>
        <Breadcrumbs style={{ margin: '24px 0' }}>
          <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/3-tipy-jak-jist-abyste-hubli">
            3 tipy, jak jíst, abyste efektivně hubli
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Container>
      <Container>
        <div className={styles.postGrid}>
          <div>
            <Hero
              title="3 tipy, jak jíst, abyste efektivně hubli"
              date="17.04.2023"
            />
            <div>
              <p className={styles.postText}>
                Snaha o hubnutí může být velmi frustrující, pokud se vám{' '}
                <b>nedaří dosáhnout výsledků</b>, i když cvičíte a omezujete
                svou stravu. <b>To ale může být přesně ten problém</b>. Spoustu
                lidí se domnívá, že pro hubnutí je potřeba přestat jíst, vyhýbat
                se “špatným” potravinám a neustále co nejvíce cvičit.{' '}
                <b>Stravování a fyzická aktivita</b> jsou skutečně{' '}
                <b>klíčové</b> pro hubnutí. Jídelníček si však musíte nastavit
                správně a tak, aby pro vás byl udržitelný. My se dnes podíváme
                na 3 tipy, jak toho docílit a následně{' '}
                <b>efektivně a zdravě zhubnout</b>.
              </p>

              <img
                src={img1}
                className={styles.postImg}
                style={{ marginBottom: '40px' }}
                alt="potraviny obsahující hořčík"
              />

              <h5 className={styles.postTitle}>1. Pravidelné stravování</h5>
              <p className={styles.postText}>
                Začít se stravovat pravidelně je <b>velmi dobrý start</b> k
                úspěšnému hubnutí. Nikde není dáno, že musíte jíst každé 2
                hodiny, nebo že byste určitě měli jíst 5x denně. Jaký režim
                zvolíte, je jen na vás. Myslete však na to, že{' '}
                <b>je potřeba za den přijmout optimální množství energie</b> pro
                vás, což může být obtížné, pokud se například rozhodnete jíst
                pouze 2x denně. Pohodlným řešením je například rozložení jídel
                do 5 porcí denně, kdy si můžete dát snídani, dopolední svačinu,
                oběd, odpolední svačinu a večeři. Díky menším, pravidelným
                porcím bude mít tělo{' '}
                <b>klid na trávení a zpracování všech živin</b>. Pokud budete{' '}
                <b>jíst pravidelně a nad jídlem přemýšlet</b>, snáze dodržíte
                váš optimální denní energetický příjem a{' '}
                <b>nebudete pak mít potřebu mlsat</b> nebo se přejídat. Kvůli
                tomu byste ve výsledku přijali mnohem více energie, než je
                potřeba. Pravidelný příjem energie vám také pomůže{' '}
                <b>udržet se v plném výkonu</b>, zabrání výkyvům nálad a také{' '}
                <b>únavě</b>.
              </p>

              <p className={styles.postText}>
                <b>TIP:</b>{' '}
                <i>
                  Pravidelné stravování bez starostí a ztraceného času můžete
                  mít jednoduše s{' '}
                  <a href="https://nutritionpro.cz/" target="_blank">
                    našimi krabičkami
                  </a>
                  !
                </i>
              </p>

              <h5 className={styles.postTitle}>2. Dostatečné množství jídla</h5>
              <p className={styles.postText}>
                Jak už jsme si řekli, velmi důležité je i to, abyste{' '}
                <b>jedli dostatečně</b>. Jedině tak vaše tělo dostane{' '}
                <b>potřebné živiny</b>, jako jsou sacharidy, bílkoviny a tuky,
                ale také důležitou vlákninu, vitaminy, minerální látky a další.
                Důležitý je také <b>dostatek energie</b>, abyste byli schopni
                přes den fungovat a splnit vše, co potřebujete. Pokud se aktivně
                snažíte zhubnout, je nutné zjistit,{' '}
                <b>jestli jíte moc anebo málo</b>. V obou případech je nutné se
                dostat do <b>lehkého kalorického deficitu</b>, pokud ovšem jíte
                moc, budete příjem snižovat, pokud jíte málo, bude možná nutné{' '}
                <b>příjem zvýšit</b>. Při hubnutí totiž není vůbec žádoucí
                drasticky jídlo omezit.
              </p>

              <h5 className={styles.postTitle}>3. Kvalitní potraviny</h5>
              <p className={styles.postText}>
                Ať se snažíte hubnout nebo ne,{' '}
                <b>kvalita jídla je naprosto zásadní</b>. Je to něco, co se
                nemusí projevit hned, ale co vás{' '}
                <b>dlouhodobě velmi ovlivňuje</b>. Snažte se konzumovat
                potraviny v jejich <b>přirozeném stavu</b> (například ovoce a
                zeleninu), dávejte <b>pozor na tepelnou úpravu</b> a snažte se
                volit šetrné formy (například horkovzdušnou fritézu místo
                smažení), vždy pečlivě <b>pročtěte složení potravin</b>, které
                si kupujete a jednoduše používejte selský rozum a vše konzumujte{' '}
                <b>s mírou</b>. Vybírejte především potraviny, které obsahují{' '}
                <b>plnohodnotné bílkoviny</b> (nebo kombinujte potraviny tak,
                abyste toho dosáhli), <b>vlákninu, komplexní sacharidy</b> a{' '}
                <b>zdravé tuky</b>. Kvalitní jídlo vám dodá potřebné živiny a
                energii, podpoří správné trávení a pomůže vám udržet se v plné
                kondici. Pokud chcete zhubnout, zvažte omezení příjmu určitých
                potravin, jako jsou například různé pochutiny či alkohol.
              </p>

              <p className={styles.postText}>
                <b>TIP:</b>{' '}
                <i>
                  Nedaří se vám zhubnout? Možná děláte jednu z{' '}
                  <a
                    href="https://nutritionpro.cz/blog/proc-se-vam-nedari-zhubnout/"
                    target="_blank"
                  >
                    těchto chyb
                  </a>
                  !
                </i>
              </p>

              <p className={styles.postText}>
                Efektivní hubnutí je jednoznačně <b>výzvou</b>. Následováním
                těchto tří tipů však můžete snadno zlepšit svůj jídelní režim a{' '}
                <b>dosáhnout svých cílů</b>. Pravidelné stravování, dostatečné
                množství jídla a kvalitní potraviny jsou <b>klíčové faktory</b>{' '}
                pro úspěšné hubnutí. Kromě toho nezapomínejte také na{' '}
                <b>pitný režim</b> a <b>pravidelnou fyzickou aktivitu</b>. Pokud
                budete dodržovat tyto zásady, můžete si být jistí, že budete
                postupovat <b>správným směrem</b> k hubnutí i zlepšení celkového
                zdraví.
              </p>

              <p className={styles.postText}>
                Autorka: Ing. Kristina Heřmanová
              </p>
            </div>
          </div>
          <PostSidebar />
        </div>
      </Container>
    </div>
  )
}

export default TipyJakJistAbysteHubli
